import { navigate } from "gatsby"
import Axios from "axios"

import { URLS } from "../../config"

import {
  PRIVATE_TRAINING_ALERT_CLEAR,
  PRIVATE_TRAINING_LOADING,
  PRIVATE_TRAINING_REG_SUCCESS,
  PRIVATE_TRAINING_REG_ERROR,
} from "../types"

export const alertClearPrivate = () => {
  return async dispatch => {
    dispatch({ type: PRIVATE_TRAINING_ALERT_CLEAR })
  }
}
/** ------------------------------------------------------------------
 *? Register FLP 2-Day Training Client
 */
export const registerFLP2DayTrainingPrivate = ({
  fname,
  lname,
  email,
  address,
  city,
  state,
  zip,
  phone,
  carrier,
  referred,
  training,
  curlOne,
  curlTwo,
  payment,
  stripeToken,
}) => {
  return async dispatch => {
    dispatch({ type: PRIVATE_TRAINING_LOADING })
    try {
      const { data } = await Axios.post(
        `${URLS.SERVER}/flp-2-day-register-private`,
        {
          fname,
          lname,
          email,
          address,
          city,
          state,
          zip,
          phone,
          carrier,
          referred,
          training,
          curlOne,
          curlTwo,
          payment,
          stripeToken,
        }
      )
      dispatch({ type: PRIVATE_TRAINING_REG_SUCCESS, payload: data.message })
      navigate("/education/trainings/private/register/success")
    } catch (error) {
      console.log(error.response)
      dispatch({
        type: PRIVATE_TRAINING_REG_ERROR,
        payload: error.response.data.message,
      })
    }
  }
}
