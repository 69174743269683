import React, { createContext, useState, useContext } from "react"
import styled from "styled-components"
import shortid from "shortid"

/**
 * Styled Components
 */
const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
`
const Danger = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 14px;
  margin: 10px;
  padding: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  &:hover {
    opacity: 0.6;
  }
`
const Success = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 14px;
  margin: 10px;
  padding: 10px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  &:hover {
    opacity: 0.6;
  }
`
const Info = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 14px;
  margin: 10px;
  padding: 10px;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  &:hover {
    opacity: 0.6;
  }
`
const Exit = styled.span`
  cursor: pointer;
  font-size: 14px;
  padding-right: 5px;
`
/**
 * Context
 */
const Ctx = createContext()

const ToastContainer = props => <Container {...props} />

const Toast = ({ children, onDismiss, alert }) => {
  if (alert === "Danger") {
    return (
      <Danger>
        <Exit onClick={onDismiss}>&#10005;</Exit>
        {children}
      </Danger>
    )
  } else if (alert === "Success") {
    return (
      <Success>
        <Exit onClick={onDismiss}>&#10005;</Exit>
        {children}
      </Success>
    )
  } else if (alert === "Info") {
    return (
      <Info>
        <Exit onClick={onDismiss}>&#10005;</Exit>
        {children}
      </Info>
    )
  } else {
    return (
      <div
        style={{
          color: "#1b1e21",
          backgroundColor: "#d6d8d9",
          borderColor: "#c6c8ca",
          cursor: "pointer",
          fontSize: 14,
          margin: 10,
          padding: 10,
        }}
        onClick={onDismiss}
      >
        {children}
      </div>
    )
  }
}

let toastCount = 0

/**
 * Provider
 */
export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])

  const add = content => {
    const { message, alert } = content
    const id = toastCount++
    const toast = { message, alert, id }
    setToasts([...toasts, toast])
  }
  const remove = id => {
    const newToasts = toasts.filter(t => t.id !== id)
    setToasts(newToasts)
  }
  // avoid creating a new fn on every render
  const onDismiss = id => () => remove(id)

  return (
    <Ctx.Provider value={{ add, remove }}>
      {children}
      <ToastContainer>
        {toasts.map(({ message, id, alert, ...rest }) => (
          <Toast
            key={shortid.generate()}
            Toast={Toast}
            onDismiss={onDismiss(id)}
            alert={alert}
            {...rest}
          >
            {message}
          </Toast>
        ))}
      </ToastContainer>
    </Ctx.Provider>
  )
}

/**
 * Consumer
 */
export const useToasts = () => useContext(Ctx)
