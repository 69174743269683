import React from "react"
import { Link } from "gatsby"
import Styled from "styled-components"

const Button1 = Styled(Link)`
    z-index: 98;
    display: inline-block;
    position: relative;
    border: none;
    color: ${({ theme }) => (theme ? theme.primaryLight : "#ffffff")};
    font-size: 18px;
    cursor: pointer;
    background: rgba(0,0,0,0.09);
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 0;
        bottom: 0;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
        transition-delay: 0.2s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => (theme ? theme.secondary : "#000000")};
    }
    &:hover::before, &:hover::after{
        width: 100%;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
    &:focus, &:active {
        outline:0;
        background: ${({ theme }) =>
          theme ? `${theme.secondary} !important` : "#000000 !important"};
    }
    &:active {
        transform: scale(1.1);
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
        }
        &:focus, &:active {
            color: ${({ theme }) =>
              theme
                ? `${theme.primaryLight} !important`
                : "#ffffff !important"};
        }
    }
`
const Button1b = Styled.button`
    z-index: 98;
    display: inline-block;
    position: relative;
    border: none;
    color: ${({ theme }) => (theme ? theme.primaryLight : "#ffffff")};
    font-size: 18px;
    cursor: pointer;
    background: rgba(0,0,0,0.09);
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 0;
        bottom: 0;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
        transition-delay: 0.2s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => (theme ? theme.secondary : "#000000")};
    }
    &:hover::before, &:hover::after{
        width: 100%;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
    &:focus, &:active {
        outline:0;
        background: ${({ theme }) =>
          theme ? `${theme.secondary} !important` : "#000000 !important"};
    }
    &:active {
        transform: scale(1.1);
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
        }
        &:focus, &:active {
            color: ${({ theme }) =>
              theme
                ? `${theme.primaryLight} !important`
                : "#ffffff !important"};
        }
    }
`
const Button2 = Styled(Link)`
    z-index: 98;
    display: inline-block;
    position: relative;
    border: none;
    color: ${({ theme }) => (theme ? theme.primaryLight : "#ffffff")};
    font-size: 18px;
    cursor: pointer;
    background: rgba(0,0,0,0.09);
    &::before {
        left: 50%;
        top: 0;
        transition-duration: 0.4s;
    }
    &::after {
        left: 50%;
        bottom: 0;
        transition-duration: 0.4s;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => (theme ? theme.secondary : "#000000")};
    }
    &:hover::before, &:hover::after {
        width: 100%;
        left: 0;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        top: 0;
    }
    &:focus, &:active {
        outline:0;
        background: ${({ theme }) =>
          theme ? `${theme.secondary} !important` : "#000000 !important"};
    }
    &:active {
        transform: scale(1.1);
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::after {
            right: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
        }
        &:focus, &:active {
            color: ${({ theme }) =>
              theme
                ? `${theme.primaryLight} !important`
                : "#ffffff !important"};
        }
    }
`
const Button2b = Styled.button`
    z-index: 98;
    display: inline-block;
    position: relative;
    border: none;
    color: ${({ theme }) => (theme ? theme.primaryLight : "#ffffff")};
    font-size: 18px;
    cursor: pointer;
    background: rgba(0,0,0,0.09);
    &::before {
        left: 50%;
        top: 0;
        transition-duration: 0.4s;
    }
    &::after {
        left: 50%;
        bottom: 0;
        transition-duration: 0.4s;
    }
    &::before, &::after {
        content:"";
        width: 0;
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => (theme ? theme.secondary : "#000000")};
    }
    &:hover::before, &:hover::after {
        width: 100%;
        left: 0;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        top: 0;
    }
    &:focus, &:active {
        outline:0;
        background: ${({ theme }) =>
          theme ? `${theme.secondary} !important` : "#000000 !important"};
    }
    &:active {
        transform: scale(1.1);
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::after {
            right: 0;
            top: 50%;
            transition-duration: 0.4s;
        }
        &::before, &::after {
            content:"";
            width:2px;
            height:0;
            position: absolute;
            transition: all 0.2s linear;
            background: ${({ theme }) => (theme ? theme.secondary : "#000000")};
        }
        &:focus, &:active {
            color: ${({ theme }) =>
              theme
                ? `${theme.primaryLight} !important`
                : "#ffffff !important"};
        }
    }
`
const Button3 = Styled(Link)`
    z-index: 98;
    display: inline-block;
    position: relative;
    border: none;
    color: ${({ theme }) => (theme ? theme.primaryDark : "#000000")};
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 15%;
        bottom: 0;
        width: 70% !important;
    }
    &::before, &::after {
        content:"";
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => (theme ? theme.primaryDark : "#000000")};
        transition-delay: 0.4s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => (theme ? theme.primaryDark : "#000000")};
    }
    &:hover::before {
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover::after {
        left: 0;
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
    &:focus, &:active {
        outline:0;
        background: ${({ theme }) =>
          theme ? `${theme.primaryDark} !important` : "#000000 !important"};
    }
    &:active {
        transform: scale(1.1);
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width: 2px;
            height: 0px;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) =>
              theme ? theme.primaryDark : "#000000"};
        }
        &:focus, &:active {
            color: ${({ theme }) =>
              theme
                ? `${theme.primaryLight} !important`
                : "#ffffff !important"};
        }
    }
`
const Button3b = Styled.button`
    z-index: 98;
    display: inline-block;
    position: relative;
    border: none;
    color: ${({ theme }) => (theme ? theme.primaryDark : "#000000")};
    font-size: 18px;
    cursor: pointer;
    background: transparent;
    &::before {
        right: 0;
        top: 0;
    }
    &::after {
        left: 15%;
        bottom: 0;
        width: 70% !important;
    }
    &::before, &::after {
        content:"";
        height: 2px;
        position: absolute;
        transition: all 0.2s linear;
        background: ${({ theme }) => (theme ? theme.primaryDark : "#000000")};
        transition-delay: 0.4s;
    }
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => (theme ? theme.primaryDark : "#000000")};
    }
    &:hover::before {
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover::after {
        left: 0;
        width: 100% !important;
        transition-delay: 0s;
    }
    &:hover span::before, &:hover span::after {
        height: 100%;
        transition-delay: 0.2s;
    }
    &:focus, &:active {
        outline:0;
        color: ${({ theme }) =>
          theme ? `${theme.primaryLight} !important` : "#ffffff !important"};
        background: ${({ theme }) =>
          theme ? `${theme.primaryDark} !important` : "#000000 !important"};
    }
    &:active {
        transform: scale(1.1);
    }
    span {
        z-index: 99;
        display: block;
        padding: 7px 14px;
        &::before {
            left: 0;
            top: 0;
        }
        &::after {
            right: 0;
            bottom: 0;
        }
        &::before, &::after {
            content:"";
            width: 2px;
            height: 0px;
            position: absolute;
            transition: all 0.2s linear;
            transition-delay: 0s;
            background: ${({ theme }) =>
              theme ? theme.primaryDark : "#000000"};
        }
        &:focus, &:active {
            color: ${({ theme }) =>
              theme
                ? `${theme.primaryLight} !important`
                : "#ffffff !important"};
        }
    }
`
const ButtonTrace = ({ children, to, disabled, onClick, className, btn }) => {
  if (onClick) {
    switch (btn) {
      case 1:
        return (
          <Button1b className={className} disabled={disabled} onClick={onClick}>
            <span>{children}</span>
          </Button1b>
        )
      case 2:
        return (
          <Button2b className={className} disabled={disabled} onClick={onClick}>
            <span>{children}</span>
          </Button2b>
        )
      case 3:
        return (
          <Button3b className={className} disabled={disabled} onClick={onClick}>
            <span>{children}</span>
          </Button3b>
        )
      default:
        return (
          <Button1b className={className} disabled={disabled} onClick={onClick}>
            <span>{children}</span>
          </Button1b>
        )
    }
  }
  switch (btn) {
    case 1:
      return (
        <Button1 to={to} className={className}>
          <span>{children}</span>
        </Button1>
      )
    case 2:
      return (
        <Button2 to={to} className={className}>
          <span>{children}</span>
        </Button2>
      )
    case 3:
      return (
        <Button3 to={to} className={className}>
          <span>{children}</span>
        </Button3>
      )
    default:
      return (
        <Button1 to={to} className={className}>
          <span>{children}</span>
        </Button1>
      )
  }
}

export default ButtonTrace
