import React from "react"
import { Link } from "gatsby"
import Styled from "styled-components"

const Button = Styled(Link)`
  letter-spacing: 5px;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.primaryLight};
  color: ${({ theme }) => theme.primaryDark};
  white-space: normal;
  border: 0;
  outline: 0;
  display: inline-block;
  line-height: 30px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 15px;
  font-weight: 600;
  border-radius: 0px;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  &:hover {
      text-decoration: none;
      background-color: ${({ theme }) => theme.primaryDark};
      color: ${({ theme }) => theme.primaryLight};
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
`

const ButtonLight = ({ children, to, className }) => (
  <Button to={to} className={className}>
    {children}
  </Button>
)

export default ButtonLight
