import { SIZE } from "../config"

const Theme = {
  primaryDark: "#000000",
  primaryLight: "#ffffff",
  secondary: "#6a959b",
  mobile: SIZE.mobileL,
}

export default Theme
