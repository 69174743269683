import React from "react"

const ExternalLink = ({ key, className, href, onClick, children }) => (
  <a
    key={key}
    className={className}
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    onClick={onClick}
  >
    {children}
  </a>
)

export default ExternalLink
