const TESTING_SERVER = false
const TESTING_STRIPE = false

const URLS = {
  SERVER: TESTING_SERVER ? `http://localhost:3333` : `https://calireeves.xyz`,
  FACEBOOK: `https://www.facebook.com/fanlikeapro`,
  INSTAGRAM: `https://www.instagram.com/_calireeves`,
}

const STRIPE = {
  API_KEY: TESTING_STRIPE
    ? `pk_test_QWR5IpC2S1vNUqN1LZ4NXExO00i1X2ukhK`
    : `pk_live_xv04ED0TOyxhXVTVRXQLAiE600BRyFJz3s`,
}

const ROUTES = [
  {
    name: "About",
    path: "/about",
    icon: "about",
  },
  {
    name: "Education",
    path: "/education",
    icon: "education",
  },
  {
    name: "Contact",
    path: "/contact",
    icon: "contact",
  },
  {
    name: "FAQs",
    path: "/faqs",
    icon: "faqs",
  },
]

const INSTAFEED = {
  USER: ``,
  CLIENT: ``,
  TOKEN: ``,
}
const FACEBOOK = {
  PIXEL: ``,
}

const GOOGLE = {
  TOKEN: ``,
}

const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tabletS: "480px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

const DEVICES = {
  maxMobileS: `(max-width: ${SIZE.mobileS})`,
  maxMobileM: `(max-width: ${SIZE.mobileM})`,
  maxMobileL: `(max-width: ${SIZE.mobileL})`,
  maxTabletS: `(max-width: ${SIZE.tabletS})`,
  maxTablet: `(max-width: ${SIZE.tablet})`,
  maxLaptop: `(max-width: ${SIZE.laptop})`,
  maxLaptopL: `(max-width: ${SIZE.laptopL})`,
  maxDesktop: `(max-width: ${SIZE.desktop})`,
  maxDesktopL: `(max-width: ${SIZE.desktop})`,
  minMobileS: `(min-width: ${SIZE.mobileS})`,
  minMobileM: `(min-width: ${SIZE.mobileM})`,
  minMobileL: `(min-width: ${SIZE.mobileL})`,
  minTabletS: `(min-width: ${SIZE.tabletS})`,
  minTablet: `(min-width: ${SIZE.tablet})`,
  minLaptop: `(min-width: ${SIZE.laptop})`,
  minLaptopL: `(min-width: ${SIZE.laptopL})`,
  minDesktop: `(min-width: ${SIZE.desktop})`,
  minDesktopL: `(min-width: ${SIZE.desktop})`,
}

const CARRIERS = [
  "",
  `ATT`,
  `TMobile`,
  `Verizon`,
  `Sprint`,
  `XFinity`,
  `Virgin`,
  `Metro`,
  `Boost`,
  `Cricket`,
  `Republic`,
  `GoogleFi`,
  `USCellular`,
  `Ting`,
  `ConsumerCellular`,
  `CSpire`,
  `PagePlus`,
]

const STATES = [
  "",
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
]

const REFERRALS = [
  "",
  "Cali Reeves",
  "Friend",
  "Instagram",
  "Facebook",
  "Snapchat",
  "Email",
  "Google",
  "N/A",
]

// $500 $1,000 $2,000
const GROUP_VALID_PAYMENTS = [
  { value: 50000, display: `$500.00` },
  { value: 100000, display: `$1,000.00` },
  { value: 200000, display: `$2,000.00` },
]

// $500 $1,000 $2,000 $2,500
const PRIVATE_VALID_PAYMENTS = [
  { value: 50000, display: `$500.00` },
  { value: 100000, display: `$1,000.00` },
  { value: 200000, display: `$2,000.00` },
  { value: 250000, display: `$2,500.00` },
]

// 2-Day FLP Registration Choice (2x)
const LASH_OPTIONS = ["", `CC`, `D`, `M`, `L`, `L+`]

export {
  URLS,
  STRIPE,
  ROUTES,
  INSTAFEED,
  FACEBOOK,
  GOOGLE,
  SIZE,
  DEVICES,
  CARRIERS,
  STATES,
  REFERRALS,
  GROUP_VALID_PAYMENTS,
  PRIVATE_VALID_PAYMENTS,
  LASH_OPTIONS,
}
