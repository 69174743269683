import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { ToastProvider } from "../Toasts"

// Master CSS Import
import "../../styles/styles.css"
// Styled Components Theme/Global
import { GlobalStyles, Theme } from "../../styles"

import Nav from "../Nav"
import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ fluid, alt, height, mobile, h1, img, children }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <Nav />
    <Header
      fluid={fluid}
      alt={alt}
      height={height}
      mobile={mobile}
      h1={h1}
      img={img}
    />
    <ToastProvider>
      <div className="container-fluid mt-5">
        <main>{children}</main>
      </div>
    </ToastProvider>
    <Footer />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
