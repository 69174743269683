import { navigate } from "gatsby"
import Axios from "axios"

import { URLS } from "../../config"

import {
  GROUP_TRAINING_LOADING,
  GROUP_TRAINING_DATES,
  GROUP_TRAINING_DATES_ERROR,
  GROUP_TRAINING_ALERT_CLEAR,
  GROUP_TRAINING_REG_SUCCESS,
  GROUP_TRAINING_REG_ERROR,
} from "../types"

/** ------------------------------------------------------------------
 *? Get FLP 2-Day Trainings
 */
export const getFLP2DayTrainings = isPrivate => {
  return async dispatch => {
    dispatch({ type: GROUP_TRAINING_LOADING })
    try {
      const { data } = await Axios.get(
        `${URLS.SERVER}/flp-2-day?isPrivate=${isPrivate}`
      )
      dispatch({ type: GROUP_TRAINING_DATES, payload: data })
    } catch (error) {
      console.log(error.response)
      dispatch({
        type: GROUP_TRAINING_DATES_ERROR,
        payload: error.response.data.message,
      })
    }
  }
}
export const alertClear = () => {
  return async dispatch => {
    dispatch({ type: GROUP_TRAINING_ALERT_CLEAR })
  }
}
/** ------------------------------------------------------------------
 *? Register FLP 2-Day Training Client
 */
export const registerFLP2DayTraining = ({
  fname,
  lname,
  email,
  address,
  city,
  state,
  zip,
  phone,
  carrier,
  referred,
  training,
  curlOne,
  curlTwo,
  payment,
  stripeToken,
}) => {
  return async dispatch => {
    dispatch({ type: GROUP_TRAINING_LOADING })
    try {
      const { data } = await Axios.post(
        `${URLS.SERVER}/flp-2-day-register-group`,
        {
          fname,
          lname,
          email,
          address,
          city,
          state,
          zip,
          phone,
          carrier,
          referred,
          training,
          curlOne,
          curlTwo,
          payment,
          stripeToken,
        }
      )
      dispatch({ type: GROUP_TRAINING_REG_SUCCESS, payload: data.message })
      navigate("/education/trainings/group/register/success")
    } catch (error) {
      console.log(error.response)
      dispatch({
        type: GROUP_TRAINING_REG_ERROR,
        payload: error.response.data.message,
      })
    }
  }
}
