import React from "react"
import PropTypes from "prop-types"
// import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

const Header = Styled.header`
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
`
const BgImage = Styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "100vh")};
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }

  @media screen and (max-width: 600px) {
    height: ${({ mobile }) =>
      mobile ? `${mobile}px !important` : "100vh !important"};
  }
  @media screen and (min-width: 1100px) {
    height: ${({ height }) => (height ? `${height * 1.5}px` : "100vh")};
  }
`
const Content = Styled.div`
  position: absolute;
  top: ${({ height }) => (height ? `${height / 2}px` : "50vh")};
  padding: 1rem;
  text-transform: uppercase;
  font-size: 3rem;
  color: ${({ theme }) => theme.primaryLight};
  @media all and (max-width: 500px) {
    font-size: 2rem;
  }
`
const ContentImg = Styled(Img)`
  z-index: 101;
  position: absolute !important;
  top: ${({ height }) => (height ? `${height / 3}px` : "33vh")};
  right: ${({ height }) => (height ? `50%` : "50%")};
  margin-right: ${({ height }) => (height ? `-${height / 2 / 2}px` : "-20vw")};
  width: ${({ height }) => (height ? `${height / 2}px` : "40vw")};
`

const MyHeader = ({ fluid, alt, height, mobile, h1, img }) => {
  if (fluid) {
    return (
      <Header>
        <BgImage fluid={fluid} alt={alt} height={height} mobile={mobile} />
        {img && (
          <div className="find-me">
            <ContentImg
              height={height}
              fluid={img}
              imgStyle={{ objectFit: "contain" }}
            />
          </div>
        )}
        {h1 && <Content height={height}>{h1}</Content>}
      </Header>
    )
  }
}

MyHeader.propTypes = {
  alt: PropTypes.string.isRequired,
  fluid: PropTypes.object.isRequired,
  height: PropTypes.number,
  heightMobile: PropTypes.number,
  children: PropTypes.node,
}

export default MyHeader
