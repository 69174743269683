import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

import ExternalLink from "../../ExternalLink"
import Icon from "./SVG/index"
import { ROUTES } from "../../../config"

const Menu = Styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background: ${({ theme }) => theme.primaryLight};
  height: 100vh;
  padding: 2rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  width: 360px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`
const MenuLogo = Styled(Img)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 200px;
`
const MenuItems = Styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
`
const Item = Styled.li`
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 360px;
    border-top: ${({ theme, border }) =>
      border ? `1px solid ${theme.primaryDark}` : `none`};
    padding-top: ${({ border }) => (border ? `10px` : `0`)};
    padding-bottom: ${({ border }) => (border ? `10px` : `0`)};
    cursor: pointer;
    &:hover {
        background-color: ${({ theme, highlight }) =>
          highlight ? theme.primaryDark : "inherit"};
        a {
            color: ${({ theme }) => theme.primaryLight};
        }
        svg {
            path {
                fill: ${({ theme }) => `${theme.primaryLight} !important`};
            }
        }
    }
    svg {
        margin-right: 10px;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
      height: 100%;
      color: ${({ theme }) => theme.primaryDark};
      font-size: 2rem;
      font-weight: 500;
      transition: color 0.3s linear;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.5rem;
        text-align: center;
      }
    }
    @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 100vw;
    }
`
const MyMenu = ({ open }) => {
  const data = useStaticQuery(graphql`
    query menuQuery {
      logoBlack: allFile(filter: { name: { eq: "logo_black_600x600" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)

  const removeOverlay = () => {
    console.log("removing overlay")
    if (
      document.getElementById("dimmer-overlay").classList.contains("dimmer")
    ) {
      document.getElementById("dimmer-overlay").classList.toggle("dimmer")
    }
  }

  const logoBlack = data.logoBlack.edges[0].node.childImageSharp.fluid
  return (
    <Menu open={open}>
      <MenuItems>
        <Item border={false} highlight={false}>
          <Link to="/" onClick={() => removeOverlay()}>
            <MenuLogo
              fluid={logoBlack}
              alt="Cali Reeves"
              imgStyle={{ objectFit: "contain" }}
            />
          </Link>
        </Item>
        {ROUTES.map(route => {
          if (route.link) {
            return (
              <Item
                key={`${route.name}-menu-link`}
                border={true}
                highlight={true}
              >
                <Icon type={route.icon} color={"#000000"} size={30} />
                <ExternalLink
                  href={`${route.link}`}
                  onClick={() => removeOverlay()}
                >
                  {route.name}
                </ExternalLink>
              </Item>
            )
          }
          return (
            <Item key={`${route.name}-menu`} border={true} highlight={true}>
              <Icon type={route.icon} color={"#000000"} size={30} />
              <Link to={`${route.path}`} onClick={() => removeOverlay()}>
                {route.name}
              </Link>
            </Item>
          )
        })}
      </MenuItems>
    </Menu>
  )
}

export default MyMenu
